import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import ActiveItemGallery from "@components/ActiveItemGallery"

import { VideoPlayer } from "@components/video"

import {
  ScrollToButton,
} from "@components/buttons"

import {
  RequestMoreInfo,
} from "@components/Forms"

const galleryItems = [
  {
    key: 1,
    title: "Taurus Floral Fabric Top T-style Guitar",
    images: ["/images/guitar003-front-body.jpg"],
  },
  {
    key: 2,
    title: "Taurus Back",
    images: ["/images/guitar003-back-body.jpg"],
  },
  {
    key: 3,
    title: "Back",
    images: ["/images/guitar003-profile.jpg"],
  },
  {
    key: 4,
    title: "Glued Fabric Top",
    images: ["/images/guitar003-side.jpg"],
  },
  {
    key: 5,
    title: "Electronics",
    images: ["/images/guitar003-back-electronics.jpg"],
  },
  {
    key: 6,
    title: "Randy King Live",
    images: ["/images/guitar003-randy-king-live.jpg"],
  },
]

const Guitar001 = () => {
  return (
    <PageLayout>
      <SEO
        title="Floral Fabric Top T-Style Guitar | Taurus"
        ogDescription="This oversized offset T-style guitar was used as the proving grounds for what became my Taurus model and has the now standard bound body with an arm carve. Let's build your dream guitar!"
      />
      <SingleColumnBlock
        textPadded
        text={
          <div className="content">
            <h1>Taurus Floral Fabric Top T-Style Guitar</h1>
            <p className="detail">
              <b>Guitar:</b> Taurus #2
            </p>
            <p className="detail">
              <b>Finish:</b> Purple Floral Pattern Fabric Top on Northern Ash body with maple neck
            </p>
            <p className="detail">
              <b>Details:</b> King Electric Guitars Taurus body, T-style bridge, and Bootstrap Palo Duro pickup
            </p>
            <ScrollToButton
              id="video"
              content="Watch Video"
              style={{ marginBottom: "20px" }}
            />
          </div>
        }
      />
      <ActiveItemGallery
        items={galleryItems}
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>This Guitar's Story</h2>
            <p>
              After building a handful of guitars, I finally got a chance to build one just for me! This guitar was used as the proving grounds for what became my Taurus model and has the design features that are now standard, such as the bound body with an arm carve. The ash body on this one begged to have the grain accentuated with a painting technique that leaves gloss in the lower areas. The custom mixed purple that adorns the back, sides & headstock was created using Mica powder & assorted dyes. I was after a cool-looking Rock N Roll monster & I believe I achieved just that.
            </p>
            <h2>Specifications</h2>
            <ul>
              <li>2-Piece Vertical Grained Maple Neck</li>
              <li>Granadillo Fretboard</li>
              <li>25.5-inch Scale</li>
              <li>12-inch Fretboard Radius</li>
              <li>Medium Cryowire Frets</li>
              <li>Dualaction Truss Rod</li>
              <li>Bolton Neck With Inserts & Stainless Machine Screws</li>
              <li>No Fretboard Inlay (Inlay Delete)</li>
              <li>Glowing Side Dots</li>
              <li>Bone Nut</li>
              <li>Hipshot Opengear Locking Tuners</li>
              <li>2-Piece Northern Ash Body</li>
              <li>Floral Fabric Top</li>
              <li>Cream Binding With arm Carve</li>
              <li>Custom Mixed Purple Back, Sides & Headstock</li>
              <li>Decorative Wood Inlay on the Back</li>
              <li>Magnetic Control Cavity Cover w/ Finger Access</li>
              <li>Urethane top Coat (Glossy top, Sanded Back & Sides to Accentuate the Grain)</li>
              <li>Gotoh Bridge</li>
              <li>Bootstrap Palo Duro Pickup</li>
              <li>CTS 250k Pots</li>
              <li>Switchcraft Jack </li>
              <li>.047 Orange Drop Cap</li>
            </ul>
            <h2>Price and Availability</h2>
            <p className="red">
              <strong>SOLD</strong>
            </p>
          </div>
        }
      />
      <div className="section textured" id="video">
          <VideoPlayer youtubeId='c6J30ummT4M' />
      </div>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Want me to build you something like it? Let's talk!</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default Guitar001